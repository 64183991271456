import styled, { css } from 'styled-components';
import { IoCloseSharp } from 'react-icons/io5';
import { useProject } from '@contexts/Project.context';

export const CloseIcon = styled(IoCloseSharp)`
	color: ${props => props.theme.colors.textColor};
	position: absolute;
	top: 4px;
	right: 2px;
	width: 28px;
	height: 28px;
	opacity: 0.7;
	transition: transform 0.1s ease-in-out;
	&:hover {
		cursor: pointer;
		opacity: 1;
	}
`;

export const Header = styled.div`
	padding: 28px 20px 0 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${props => props.theme.colors.background};
`;

const HeaderName = styled.p`
	font-size: 1rem;
	margin: -0.9rem 0 1rem;
	opacity: 0.7;
	font-weight: 300;
`;

export const HeaderText = styled.h2`
	font-size: 1.2rem;
	font-weight: 700;
	text-transform: capitalize;
`;

export const Container = styled.div`
	overflow-y: auto;
	overflow-x: hidden;
	z-index: ${props => props.zindex};
	position: absolute;

	top: 8px;
	left: ${props => (props.isChild ? '28px' : '8px')};

	max-height: calc(100% - 56px);
	width: ${props => (props.width === 'normal' ? '340px' : '430px')};
	color: ${props => props.theme.colors.textColor};
	transition: all 0.2s ease-in-out;
	display: flex;
	flex-direction: column;
	flex: 1;

	opacity: 1;
	display: ${props => (props.hidden ? 'none' : 'flex')};

	${props =>
		props.disabled &&
		css`
			opacity: 0.5;
			pointer-events: none;
		`}

	${props =>
		props.align === 'right' &&
		css`
			opacity: 1;
			left: auto;
			right: ${props =>
				props.hidden ? `-${props.theme.utils.sidebarWidth}` : '8px'};
		`}

	${props =>
		props.align === 'bottom' &&
		css`
			bottom: 48px;
			top: auto;
		`}

	${props =>
		props.align === 'center' &&
		css`
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		`}
`;

const ChildrenContainer = styled.div`
	> * {
		background-color: ${props => props.theme.colors.background};

		&:not(:first-child) {
			margin-top: 10px;
		}
	}
`;

/**
 * This component is a template for the sidebars that are used in the map view.
 * @param {string} header - The header text for the sidebar
 * @param {ReactNode} children - The content of the sidebar
 * @param {boolean} activeStatus - The status of the sidebar. True if the sidebar is active, false if it is inactive
 * @param {function} setActiveStatus - A function that sets the activeStatus of the sidebar
 * @param {boolean} closeable - A boolean that determines if the sidebar is closeable or not
 */
const SidebarTemplate = ({
	sidebarId,
	header,
	closeable,
	zindex = 1,
	align = 'left',
	hideHeaderName = false,
	width = 'normal',
	forceActiveStatus = false,
	onHide = false,
	children,
}) => {
	const { project, activeSidebars, removeFromActiveSidebars } = useProject();

	const activeElement = activeSidebars.find(
		sidebar => sidebar.sidebarId === sidebarId
	);

	if (!forceActiveStatus && !activeElement) return null;

	const activeChildExists = activeSidebars.find(
		sidebar => sidebar.parentId === sidebarId
	);

	const doHide = () => {
		if (onHide) onHide();
		removeFromActiveSidebars(sidebarId);
	};

	const content = (
		<Header>
			<div>
				{project?.title && !closeable && !hideHeaderName && (
					<HeaderName>{project.title}</HeaderName>
				)}
				<HeaderText>{header}</HeaderText>
			</div>
			{closeable && <CloseIcon onClick={doHide} />}
		</Header>
	);

	return (
		<Container
			zindex={zindex}
			align={align}
			width={width}
			hidden={forceActiveStatus && !activeElement}
			disabled={activeChildExists}
			isChild={!!activeElement?.parentId}>
			{content}
			<ChildrenContainer>{children}</ChildrenContainer>
		</Container>
	);
};

export default SidebarTemplate;
