import { NavLink, Link } from 'react-router-dom';
import styled from 'styled-components';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import Alert from 'react-bootstrap/Alert';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import { FaUserAlt } from 'react-icons/fa';

import logo from '../../assets/logo/logo_white.svg';
import Notifications from '../notifictions/Notifications';
import TrialBanner from './trialBanner.component';
import { useAuth } from '@contexts/User.context';

import OrganizationSwitch from '../organization/organizationSwitch';

const StyledNavbar = styled(Navbar)`
	background-color: ${props => props.theme.colors.background};
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
	z-index: 100;
	.container-fluid {
		z-index: 100;
		position: relative;
	}
	.nav-link {
		margin: 5px;
		font-weight: 500;
		letter-spacing: 1px;
	}
	.navbar-toggler {
		border: none;
	}
`;

const StyledNav = styled(Nav)`
	@media (min-width: 992px) {
		border-right: 1px solid hsl(0, 0%, 30%);
	}
`;

const NavContent = styled.div`
	@media (min-width: 992px) {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
	}
`;

const StyledNavLink = styled(Nav.Link)`
	color: ${props => props.theme.colors.textColor};
	margin: 5px;
	font-weight: 500;
	letter-spacing: 1px;
	padding: 5px;
	text-decoration: none;
	&.active {
		color: ${props => props.theme.colors.green};
	}
	&:hover {
		color: ${props => props.theme.colors.lightGreen};
	}
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
	background-color: ${props => props.theme.colors.backgroundAccent};
`;

const StyledDropdownItem = styled(Dropdown.Item)`
	&.active {
		background: none;
		color: ${props => props.theme.colors.lightGreen};
	}
	&:hover {
		color: ${props => props.theme.colors.lightGreen};
	}
`;

const DropdownTitle = styled.span`
	font-size: 0.6rem;
	padding: 6px 16px;
	opacity: 0.7;
	font-weight: 300;
	display: block;
	transform: translateY(-3px);
	letter-spacing: 0.1em;
`;

const StyledLogoutButton = styled(Button)`
	width: 100px;
`;

const Brand = () => (
	<Navbar.Brand as={Link} to="/">
		<img
			src={logo}
			width="155"
			height="45"
			className="d-inline-block align-top"
			alt="React Bootstrap logo"
		/>
	</Navbar.Brand>
);

const Navigation = () => {
	const { currentUser } = useAuth();

	return (
		<>
			<StyledNavbar fixed="top" expand="lg" variant="dark">
				<Container fluid>
					{currentUser?.loggedIn ? (
						<LogedInNavigation />
					) : (
						<LogedOutNavigation />
					)}
				</Container>
			</StyledNavbar>
		</>
	);
};

export default Navigation;

const LogedInNavigation = () => {
	const {
		currentUser,
		logoutCurrentUser,
		roleAdmin,
		isOrgAdmin,
		tierTrial,
		tierPro,
		subscriptionActive,
	} = useAuth();

	const showFullMenu = (tierPro && subscriptionActive) || !tierPro;

	return (
		<>
			<div className="d-flex w-100 align-items-center justify-content-between justify-content-lg-start">
				<Brand />

				<OrganizationSwitch />

				{tierTrial && <TrialBanner />}

				{
					// We need users to add their name to the profile because of Stripe
				}
				{!tierTrial && !currentUser?.name && (
					<Alert
						variant="warning"
						className="py-1 px-2 small mt-3 ms-2">
						<Link className="inherit" to="/profile">
							Please update your profile
						</Link>
					</Alert>
				)}

				<Navbar.Toggle aria-controls="main-navbar" />
			</div>

			<Navbar.Collapse id="main-navbar">
				<NavContent>
					{tierPro && !subscriptionActive && (
						<>
							<Nav className="me-3 pe-2">
								<StyledNavLink as={NavLink} to="/">
									Home
								</StyledNavLink>
								<StyledNavLink as={NavLink} to="/profile">
									Profile
								</StyledNavLink>
							</Nav>
							<StyledLogoutButton
								onClick={logoutCurrentUser}
								variant="dark"
								size="sm">
								Log out
							</StyledLogoutButton>
						</>
					)}

					{showFullMenu && (
						<>
							<StyledNav className="me-3 pe-2">
								<StyledNavLink as={NavLink} to="/">
									Home
								</StyledNavLink>
								<StyledNavLink as={NavLink} to="/projects">
									Projects
								</StyledNavLink>
							</StyledNav>

							<div className="d-flex gap-3">
								<Dropdown align="end">
									<Dropdown.Toggle
										id="dropdown-user-menu"
										className="btn-dark">
										<FaUserAlt />
									</Dropdown.Toggle>

									<StyledDropdownMenu variant="dark">
										{roleAdmin && (
											<>
												<DropdownTitle>
													Admin Menu
												</DropdownTitle>
												<StyledDropdownItem
													as={NavLink}
													to="/admin/organizations"
													className="dropdown-item">
													Organizations
												</StyledDropdownItem>
												<StyledDropdownItem
													as={NavLink}
													to="/admin/users"
													className="dropdown-item">
													Users
												</StyledDropdownItem>
												<StyledDropdownItem
													as={NavLink}
													to="/admin/projects"
													className="dropdown-item">
													Projects
												</StyledDropdownItem>
											</>
										)}

										{!roleAdmin && isOrgAdmin && (
											<>
												<DropdownTitle>
													Admin Menu
												</DropdownTitle>
												<StyledDropdownItem
													as={NavLink}
													to={`organization/${currentUser?.active_org_id}/projects`}
													className="dropdown-item">
													Organization
												</StyledDropdownItem>
											</>
										)}

										{tierPro && (
											<>
												<StyledDropdownItem
													as={NavLink}
													to="/storage-subscription"
													className="dropdown-item">
													Subscription
												</StyledDropdownItem>

												<StyledDropdownItem
													as={NavLink}
													to="/area-transactions"
													className="dropdown-item">
													Area
												</StyledDropdownItem>
											</>
										)}

										<StyledDropdownItem
											as={NavLink}
											to="/profile"
											className="dropdown-item">
											User profile
										</StyledDropdownItem>

										<Dropdown.Divider />
										<Dropdown.Item
											as="button"
											onClick={logoutCurrentUser}>
											Log out
										</Dropdown.Item>
									</StyledDropdownMenu>
								</Dropdown>

								<Notifications />
							</div>
						</>
					)}
				</NavContent>
			</Navbar.Collapse>
		</>
	);
};

const LogedOutNavigation = () => (
	<>
		<Brand />
		<Navbar>
			<Nav>
				<Button variant="success" as={Link} to="/login">
					Log in
				</Button>
			</Nav>
		</Navbar>
	</>
);
