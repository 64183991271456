import { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useQuery } from '@tanstack/react-query';

import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { AiFillBell } from 'react-icons/ai';

import Notification from './Notification';

import { getUserNotifications } from '@utils/api';
import { useAuth } from '@contexts/User.context';

const StyledToggleButton = styled(Button)`
	font-size: 1.2rem;
	padding-top: 3px;
	padding-bottom: 3px;
	&[aria-pressed='true'] {
		background-color: ${p => p.theme.colors.backgroundAccent};
	}
`;

const Ring = keyframes`
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }
    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
`;

const IconWrapper = styled.span`
	position: relative;
	display: block;

	${p =>
		p.$hasunread &&
		css`
			animation: ${Ring} 4s ease-in-out;
			&:after {
				content: '';
				position: absolute;
				top: 5px;
				right: -1px;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: ${p.theme.colors.green};
				border: 2px solid ${p.theme.colors.background};
				[aria-pressed='true'] &,
				button:hover & {
					border-color: ${p => p.theme.colors.backgroundAccent};
				}
			}
		`}
`;

const Backdrop = styled.div`
	position: fixed;
	z-index: 98;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
`;

const NotificationsContainer = styled.aside`
	position: fixed;
	z-index: 99;
	right: 0;
	top: 70px;
	width: 25vw;
	min-width: 426px;
	max-width: 680px;
	max-height: calc(100vh - 70px);
	overflow-y: auto;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	background-color: ${p => p.theme.colors.background};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	padding: 1rem;
`;

const NotificationsList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const Notifications = () => {
	const [open, setOpen] = useState(false);

	const { currentUser } = useAuth();

	const { data, isLoading } = useQuery({
		queryKey: [
			'notifications',
			currentUser?.uuid,
			currentUser?.active_org_id,
		],
		queryFn: getUserNotifications,
		enabled: !!currentUser?.uuid,
		refetchInterval: 1000 * 60 * 5, // 5 minutes
		refetchOnWindowFocus: false,
	});

	const notifications = data?.notifications || [];
	const hasUnread = notifications.some(
		n => n.notification_status === 'unread'
	);

	return (
		<>
			<StyledToggleButton
				variant="dark"
				aria-pressed={open}
				disabled={isLoading}
				title={open ? 'Close notifications' : 'Open notifications'}
				onClick={() => setOpen(!open)}
				$hasunread={hasUnread}>
				{isLoading ? (
					<Spinner
						animation="border"
						variant="light"
						size="sm"
						role="status">
						<span className="visually-hidden">
							Checking for notifications
						</span>
					</Spinner>
				) : (
					<IconWrapper $hasunread={hasUnread}>
						<AiFillBell />
					</IconWrapper>
				)}
			</StyledToggleButton>
			{open && (
				<>
					<Backdrop onClick={() => setOpen(false)} />
					<NotificationsContainer>
						{notifications.length > 0 ? (
							<NotificationsList>
								{notifications.map(notification => (
									<Notification
										key={notification.uuid}
										notification={notification}
									/>
								))}
							</NotificationsList>
						) : (
							<p className="text-center">No notifications</p>
						)}
					</NotificationsContainer>
				</>
			)}
		</>
	);
};

export default Notifications;
