import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';

import { getNotification, getProject } from '@utils/api';
import CircularProgress from './CircularProgress';

const StyledNotification = styled.li`
	border-radius: 6px;
	background-color: ${p => p.theme.colors.backgroundAccent};

	font-size: 14px;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	gap: 10px;
	position: relative;
	padding: 1rem;
`;

const Description = styled.div`
	font-size: 14px;
	color: ${p => p.theme.colors.textAccent};
	p {
		margin: 0;
	}
	span {
		text-transform: capitalize;
	}
`;

const Notification = ({ notification: defaultData }) => {
	const [intervalMs, setIntervalMs] = useState(false);

	if (!defaultData) return null;

	const { data } = useQuery({
		queryKey: ['notification', defaultData.uuid],
		queryFn: () => getNotification(defaultData.uuid),
		initialData: defaultData,
		refetchInterval: intervalMs,
		enabled: !!intervalMs,
	});
	const notification = data?.notification || defaultData;
	const {
		notification_type,
		project_uuid,
		model_uuid,
		job_status,
		updated_at,
		message,
	} = notification;

	const { progress, ...text } = message;

	const textOutput =
		text &&
		Object.values(text).map(value => {
			if (typeof value !== 'string') return null;
			return value;
		});

	useEffect(() => {
		if (!job_status || !notification_type) return;

		if (
			(job_status === 'running' || job_status === 'pending') &&
			notification_type === 'job'
		) {
			setIntervalMs(1000 * 60);
		} else {
			setIntervalMs(false);
		}
	}, [job_status, notification_type]);

	return (
		<StyledNotification role="status">
			<div>
				{project_uuid && (
					<ProjectDetails
						project_uuid={project_uuid}
						model_uuid={model_uuid}
					/>
				)}

				<Description>
					{textOutput &&
						textOutput.map((val, index) => (
							<p key={`${notification.uuid}-${index}`}>{val}</p>
						))}

					{updated_at && (
						<p>
							{new Date(updated_at).toLocaleDateString('nb-NO', {
								hour: 'numeric',
								minute: 'numeric',
								day: 'numeric',
								month: 'short',
								year: 'numeric',
							})}
						</p>
					)}
				</Description>
			</div>

			{progress && (
				<CircularProgress
					size={40}
					strokeWidth={3}
					progress={Math.ceil(progress)}
					status={job_status}
				/>
			)}
		</StyledNotification>
	);
};

export default Notification;

const ProjectDetails = ({ project_uuid, model_uuid }) => {
	if (!project_uuid) return null;

	const { data } = useQuery({
		queryKey: ['project', project_uuid],
		queryFn: () => getProject(project_uuid),
	});

	const { title, uuid } = data || {};

	if (!title) return null;

	const url = model_uuid
		? `/projects/${uuid}/?model=${model_uuid}`
		: `/projects/${uuid}`;

	return (
		<Link to={url} reloadDocument className="inherit">
			{title}
		</Link>
	);
};
