import { Button, Spinner } from "react-bootstrap";
import { KeyboardShortcut } from "@components/map/sharedStyles.component";

export default function UtilityButton({
	icon: Icon,
	label,
	tooltip, //{id, content, place}
	onClick,
	variant,
	disabled = false,
	loading,
	keyboardShortcutLabel,
 }) {

	return (
		<Button
			disabled={disabled}
			onClick={onClick}
			variant={variant}
			data-tooltip-id={tooltip?.id}
			data-tooltip-content={tooltip?.content}
			data-tooltip-place={tooltip?.place}
		>
			<span>
				{Icon && <Icon />}
				{label}
				{keyboardShortcutLabel && <KeyboardShortcut>{keyboardShortcutLabel}</KeyboardShortcut>}
				{loading && <Spinner size="sm" className="ms-2" /> }
			</span>
		</Button>
	);
}
