import { intersects } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON.js';
import { Style, Fill, Stroke, RegularShape } from 'ol/style';
import { MultiPoint } from 'ol/geom';

import { calculateGeometryCenter } from '../helpers';

export let activeSquareRef = null;
export let annotationClassId = null;

// function that returns true if the point is inside the square using black magic
export const isPointInsideSquare = (point, square) => {
	const squareCoordinates = square.geometry.coordinates[0];
	const x = point[0];
	const y = point[1];

	const x1 = squareCoordinates[0][0];
	const y1 = squareCoordinates[0][1];
	const x2 = squareCoordinates[1][0];
	const y2 = squareCoordinates[1][1];
	const x3 = squareCoordinates[2][0];
	const y3 = squareCoordinates[2][1];
	const x4 = squareCoordinates[3][0];
	const y4 = squareCoordinates[3][1];

	const d1 = (x - x1) * (y2 - y1) - (y - y1) * (x2 - x1);
	const d2 = (x - x2) * (y3 - y2) - (y - y2) * (x3 - x2);
	const d3 = (x - x3) * (y4 - y3) - (y - y3) * (x4 - x3);
	const d4 = (x - x4) * (y1 - y4) - (y - y4) * (x1 - x4);

	const hasNeg = d1 < 0 || d2 < 0 || d3 < 0 || d4 < 0;
	const hasPos = d1 > 0 || d2 > 0 || d3 > 0 || d4 > 0;

	return !(hasNeg && hasPos);
};

// Function that returns true if the feature is intersecting the square
// @TODO: This could propably replace isPointInsideSquare
export const isFeatureIntersectingSquare = (feature, square) => {
	// Convert square to feature
	const squareFeature = new GeoJSON().readFeature(square);

	// Get bounding boxes of geometries
	const extent1 = feature.getGeometry()?.getExtent();
	const extent2 = squareFeature.getGeometry()?.getExtent();

	// Check if bounding boxes intersect
	const isIntersecting = extent1 && extent2 && intersects(extent1, extent2);
	return isIntersecting;
};

export const setSquareBoundary = activeSquare => {
	if (activeSquare !== undefined) {
		activeSquareRef = activeSquare;
	}
	activeSquareRef = activeSquare;
};

export const addAnnotationId = classId => {
	annotationClassId = classId;
};

const getGeometry = feature => {
	const modifyGeometry = feature.get('modifyGeometry');
	return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
};

/**
 * Annotation styles
 * @param {} feature
 * @param {*} colorOptions color options for the annotation
 * @returns feature styles
 */
export const annotationStyles = (feature, colorOptions) => {
	const geometry = getGeometry(feature);
	const result = calculateGeometryCenter(geometry);

	const classId = feature.values_?.classId ?? 0;
	const { r = 0, g = 0, b = 0 } = colorOptions[classId] ?? {};
	let colorOpcacity = '0.5';

	// Style for the annotation
	const styles = [];

	const isInside = isFeatureIntersectingSquare(feature, activeSquareRef);
	if (!isInside) {
		feature.set('isInside', false);
	} else {
		// Feature is inside and can be modified
		feature.set('isInside', true);
		colorOpcacity = '1';

		// Add draggable vertices
		let points;

		if (geometry.getType() === 'Circle') {
			const center = geometry.getCenter();
			const radius = geometry.getRadius();

			points = [];

			// Add points along the circumference
			const numPoints = 4; // Number of points along the circumference
			for (let i = 0; i < numPoints; i++) {
				const angle = (i * 2 * Math.PI) / numPoints;
				const x = center[0] + radius * Math.cos(angle);
				const y = center[1] + radius * Math.sin(angle);
				points.push([x, y]);
			}
		} else {
			const { coordinates, minRadius, sqDistances } = result;

			if (coordinates && sqDistances) {
				const rsq = minRadius * minRadius;

				// Filter coordinates based on the squared distance
				points = coordinates.filter(
					(coordinate, index) => sqDistances[index] > rsq
				);

				// Calculate midpoints and add them to the points array @TODO: This could be used to add more vertices
				// for (let i = 0; i < coordinates.length; i++) {
				// 	const nextIndex = (i + 1) % coordinates.length;
				// 	const midpoint = [
				// 		(coordinates[i][0] + coordinates[nextIndex][0]) / 2,
				// 		(coordinates[i][1] + coordinates[nextIndex][1]) / 2,
				// 	];
				// 	points.push(midpoint);
				// }
			}
		}

		if (points?.length) {
			// Create and push the new style
			styles.push(
				new Style({
					geometry: new MultiPoint(points),
					image: new RegularShape({
						radius: 5,
						points: 4,
						angle: Math.PI / 4,
						fill: new Fill({
							color: 'rgba(255, 255, 255, 0.7)',
						}),
						stroke: new Stroke({
							color: '#000',
							width: 1,
						}),
					}),
				})
			);
		}
	}

	// Style for the annotation
	styles.push(
		new Style({
			geometry: getGeometry,
			fill: new Fill({
				color: 'rgba(0, 0, 0, 0.25)',
			}),
			stroke: new Stroke({
				color: `rgba(${r}, ${g}, ${b}, ${colorOpcacity})`,
				width: 3,
			}),
		})
	);

	return styles;
};
