import { api } from './index.js';

const apiEndpoint = '/notifications';

export const updateNotification = async ({ uuid, data }) => {
	const { data: response } = await api.put(
		`${apiEndpoint}/${uuid}/status`,
		data
	);

	return response;
};

export const createGenericNotification = async data => {
	const { data: response } = await api.post(`${apiEndpoint}/generic`, data);

	return response;
};

/*  Updates the message of a notification in the database
 *  Can also update an existing message key in the message dictionary
 *  e.g.
 *  overwrite the existing message with the new message: sample_dict = {"text": "Sleijn Steikjepanne"}
 *  add a new key to the message dictionary: sample_dict = {"text2": "Sleijn Steikjepanne"}
 */
export const addNotificationMessage = async ({
	uuid,
	id = 'text',
	message,
}) => {
	const data = {
		id,
		message,
	};

	const { data: response } = await api.put(
		`${apiEndpoint}/${uuid}/message`,
		data
	);

	return response;
};

export const getUserNotifications = async () => {
	const { data } = await api.get(`${apiEndpoint}/user`);

	return data;
};

export const getUserUnreadNotifications = async () => {
	const { data } = await api.get(`${apiEndpoint}/user/unread`);

	return data;
};

export const getNotification = async uuid => {
	const { data } = await api.get(`${apiEndpoint}/${uuid}`);

	return data;
};
